import "./App.css";
import "./components/Components.css";
import React, { Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navigation from "./components/Navbar";

const Home = React.lazy(() => import("./components/Home"));
const About = React.lazy(() => import("./components/About"));
const Shuffle = React.lazy(() => import("./components/Shuffle"));
const MiniProject = React.lazy(() => import("./components/projects/MiniProject/MiniProject"));

function App () {
		return (
			<div className="App">
				<Router>
					<Navigation />
					<Suspense fallback={<p> Loading...</p>}>
					<Routes>
						<Route path="/" element={<Home />}/>
						<Route path="/about" element={<About />}/>
						<Route path="/projects/ece-362-mini-project" element={<MiniProject />}/>
						<Route path="/party" element={<Party />} />
						<Route path="/shuffle" element={<Shuffle />} />
						<Route path="*" element={<h1 className="style_404">
								Unfortuantely this page hasn&apos;t been
								implemented yet. Check out one of the links
								above instead
							</h1>}/>
							
					</Routes>
					</Suspense>
				</Router>
			</div>
		);
}

const Party = () => {
	window.location.href = "https://cultofthepartyparrot.com";
	return <></>;
};

export default App;
