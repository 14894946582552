import React from "react";
import { Nav, Navbar /*, Dropdown*/ } from "react-bootstrap";
import { Link } from "react-router-dom";

function Navigation() {
	// constructor(props) {
	//     super(props)
	//     this.state = { dropdownOpen: false }
	//     this.handleOpen = this.handleOpen.bind(this)
	//     this.handleClose = this.handleOpen.bind(this)
	// }

	// handleOpen = () => {
	//     this.setState({ dropdownOpen: true })
	// }

	// handleClose = () => {
	//     this.setState({ dropdownOpen: false })
	// }

	const myStyle = {
		backgroundColor: "#1B1B1E",
		borderColor: "#1B1B1E",
		color: "#CED9DF",
	};
	return (
		<Navbar style={myStyle} variant="dark">
			<div className="Navigation">
				<Nav>
					<Navbar.Brand as={Link} to="/" style={myStyle}>
						Jake Tockerman
					</Navbar.Brand>
					<Nav.Link as={Link} to="/shuffle" style={myStyle}>
						Shuffle
					</Nav.Link>
					{/*<Nav.Link as={Link} to = "/about" style={myStyle}>About</Nav.Link>*/}
					{/* <Dropdown rendermenuonmount="true">
                            <Dropdown.Toggle style={myStyle}
                                    //    onMouseEnter = { this.handleOpen }
                                    //    onMouseLeave = { this.handleClose }
                                    //    open={ this.state.dropdownOpen }      
                            >
                                Projects
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item as={Link} to="/projects/ece-362-mini-project" >ECE 362 Mini Project</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown> */}
				</Nav>
			</div>
		</Navbar>
	);
}

export default Navigation;
